<template>
    <div class="pageBox">
        <div class="mainPage">
            <div class="typebox" v-if="options.length>0">
                <div class="titleType">
                    项目成果分类
                </div>
                <div class="lineTypeBox">
                    <div :class="`lineType ${item.type==query.types?'lineActive':''}`" 
                    @click="GetDatas(item.type)"
                    v-for="(item, index) in options" 
                    :key="index">{{ item.name }}</div>
                </div>
            </div>
            <div class="typeTable">
                <div class="posi" @click="goback">项目成果/更多</div>
                <div class="tabelBox">
                    <div class="BZline" @click="BZopen(item.path)"  v-for="(item,index) in tabelList" :key="index">
                    ■ {{ item.name }}
                    </div>
                </div>
                
                <div class="pagination"> 
                    <el-pagination 
                    layout="total, prev, pager, next" 
                    :current-page="query.page"
                    :page-size="query.size"
                    @current-change="pageChange"
                    :total="total" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            bigType:1, //1项目成果
            options:[],
            tabelList:[],
            query:{
                page:1,
                size:10,
                types:null,
            },
            total:0,
        }
    },
    mounted(){
        this.getOption()
        
    },
    methods:{
        getOption(){
            this.options = fileOption.find(i=>{
                return i.type == this.bigType
            }).children
            if(this.options.length>0){
                this.GetDatas(this.options[0].type)
            }else{
                this.GetDatas(this.bigType)
            }
        },
        GetDatas(id){
            this.query.types = id
            this.CX(0)
        },
        pageChange(page){
            this.query.page = page
            this.CX(1)
        },
        CX(type){
            if(type == 0){
                this.query.page = 1
            }
            let tabelList = fileList.filter(el=>{
                return el.type == this.query.types
            })
            this.tabelList = tabelList.slice(this.query.size*(this.query.page-1), this.query.size*this.query.page)    
            this.total = tabelList.length
        },
        goback(){
            this.$router.go(-1)
        },
        BZopen(path){
            window.open(path,'_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width: 100%;
    box-sizing: border-box;
}
.mainPage{
    width: 1300px;
    margin:  0 auto;
    padding: 70px 0px 50px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .typebox{
        width: 260px;
        height: 700px;
        background-color: #e5edff;
        margin-right: 10px;
        .titleType{
            width: 100%;
            height: 80px;
            line-height: 80px;
            background: #3073FF;
            font-size: 20px;
            text-align: center;
            color: white;
        }
        .lineTypeBox{
            width: 100%;
            height: 600px;
            overflow: auto;
        }
        .lineType{
            line-height: 40px;
            padding-left: 50px;
            border-bottom: 1px solid rgb(246, 246, 246);
            box-sizing: border-box;
            cursor: pointer;
        }
        .lineType:hover{
            background-color: #BFD1F7;
            border-right: 2px solid #6D9DFF;
        }
        .lineActive{
            background-color: #BFD1F7;
            border-right: 2px solid #6D9DFF;
        }
    }
    .typeTable{
        flex: 1;
        background-color: rgb(255, 255, 255);
        padding: 20px;
        box-sizing: border-box;
        .posi{
            margin-bottom: 10px;
            color: #666;
            font-size: 14px;
            cursor: pointer;
            width: 100px;
        }
        .tabelBox{
            width: 100%;
            min-height: 500px;
            .BZline{
                width: 100%;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid rgb(218, 218, 218);
                box-sizing: border-box;
                overflow: hidden;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis; 
            }
        }
        
    }
}
.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>